import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import servicesData from "../data/services";
import "../Styles/Services.css";

const ServiceDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [bgLoaded, setBgLoaded] = useState(false);

  const service = Object.values(servicesData)
    .flat()
    .find((service) => service.slug === slug);

  useEffect(() => {
    const img = new Image();
    img.src = "/images/individual-service-banner.webp";
    img.onload = () => setBgLoaded(true);
  }, []);

  if (!service) {
    return <div className="services-container">Service not found.</div>;
  }

  return (
    <div className="services-container">
      <div
        className={`banner ${bgLoaded ? "loaded" : ""}`}
        style={{ backgroundImage: `url('/images/individual-service-banner.webp')` }}
      >
        <div className="banner-overlay">{service.name}</div>
      </div>

      <div className="service-details">
        {service.details.map((detail, index) => (
          <div key={index} className="service-detail-section">
            <h2>{detail.header}</h2>
            <p>{detail.info}</p>
          </div>
        ))}
      </div>

      <button className="back-button" onClick={() => navigate("/services")}>
        Back to Services
      </button>
    </div>
  );
};

export default ServiceDetail;