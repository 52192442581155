import React from "react";
import "../Styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-hours">
          <h3>Business Hours</h3>
          <div className="hours-list">
            <div className="hour-row"><span>Monday</span> <span>9am – 7pm</span></div>
            <div className="hour-row"><span>Tuesday</span> <span>2pm – 7pm</span></div>
            <div className="hour-row"><span>Wednesday</span> <span>9am – 7pm</span></div>
            <div className="hour-row"><span>Thursday</span> <span>2pm – 7pm</span></div>
            <div className="hour-row closed"><span>Friday - Sunday</span> <span>Closed</span></div>
          </div>
        </div>
        <div className="footer-address">
          <p>1991 W. 60th St., Hialeah, FL 33012</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;