import React from "react";
import { Link } from "react-router-dom";
import { FaCertificate, FaUserMd, FaHospital, FaGraduationCap } from 'react-icons/fa';
import "../Styles/Home.css";

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <div className="hero-section">
        <img src="/images/hero.webp"alt="Home" className="hero-image" loading="eager" fetchpriority="high"/>
        <div className="hero-overlay">
          <h1>Welcome to Miramar Pain Medicine</h1>
          <p>This is where healing begins</p>
        </div>
      </div>

      {/* About Section */}
      <div className="about-section">
        <div className="about-text">
          <h2>ABOUT US</h2>
          <p>
            Miramar Pain Medicine is located in the heart of Hialeah. Home to leading health professionals dedicated to 
            helping you achieve your wellness objectives — combining skill and expertise that spans the entire pain 
            management spectrum.
          </p>
          <Link to="/services" className="services-button">
            Our Services
          </Link>
        </div>
        <div className="about-video">
          <iframe
            src="https://player.vimeo.com/video/313820844?h=c46a19f335"
            title="About Us Video"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* Doctor Bio Section */}
      <div className="doctor-bio-section">
        <div className="doctor-bio-wrapper">
          <div className="doctor-bio-content">
            <div className="doctor-bio-text">
              <h2>Ignacio J. Rodriguez, MD</h2>
              <p>
                Born in Cuba and raised in Chicago, Dr. Rodriguez has spent the past 30 years living in Miami with his family.
                A dedicated expert in clinical pain management since 1994, he has deepened his knowledge through extensive practice and research. 
                He collaborates with leading pharmaceutical companies to explore cutting-edge medications and innovative treatments for chronic pain. 
                Outside of his professional work, he enjoys early morning cycling and unwinding with a glass of red wine.
              </p>
            </div>
            <div className="doctor-bio-image">
              <img src="/images/doctor-headshot.webp" alt="Dr. Rodriguez" />
              <p className="doctor-quote">
                “Each of my patients is unique and has their own pain story.
                My goal is to build a trusting and beneficial relationship with my patients in order to work towards their goals and pain relief.”
              </p>
              <p className="doctor-signature">- Dr. Rodriguez</p>
            </div>
          </div>
          <div className="doctor-badges">
            <div className="badge">
              <div className="badge-header">
                <FaCertificate className="badge-icon" />
                <h3>Board Certifications</h3>
              </div>
              <p>American Board Of Anesthesiology, American Board of Pain Medicine</p>
            </div>
            <div className="badge">
              <div className="badge-header">
                <FaUserMd className="badge-icon" />
                <h3>Medical Societies</h3>
              </div>
              <p>American Society of Anesthesiologists, Florida Society of Anesthesiologists, American Society of Pain Medicine</p>
            </div>
            <div className="badge">
              <div className="badge-header">
                <FaHospital className="badge-icon" />
                <h3>Residency</h3>
              </div>
              <p>Jackson Memorial Hospital, Miami, FL</p>
            </div>
            <div className="badge">
              <div className="badge-header">
                <FaGraduationCap className="badge-icon" />
                <h3>Medical School</h3>
              </div>
              <p>University of Illinois College of Medicine</p>
            </div>
          </div>
        </div>
      </div>

      {/* Certifications Section*/}
      <div className="certifications-section">
        <div className="certifications-row">
          <img src="/images/ABA.webp" alt="ABA Certification" className="certification-image" />
          <img src="/images/NANS.webp" alt="NANS Certification" className="certification-image" />
          <img src="/images/AMA.webp" alt="AMA Certification" className="certification-image" />
          <img src="/images/AAPM.webp" alt="AAPM Certification" className="certification-image" />
        </div>
      </div>
    </div>
  );
};

export default Home;
