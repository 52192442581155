import React, { useState, useEffect } from "react";
import "../Styles/Faqs.css";

const Faqs = () => {
  const [bgLoaded, setBgLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = "/images/faqs-banner.webp";
    img.onload = () => setBgLoaded(true);
  }, []);

  return (
    <div className="faqs-container">
      <div
        className={`banner ${bgLoaded ? "loaded" : ""}`}
        style={{ backgroundImage: `url('/images/faqs-banner.webp')` }}
      >
        <div className="banner-overlay">Frequently Asked Questions</div>
      </div>
      <div className="faqs-content">
        <div className="faq-item">
          <h3 className="faq-question">How do I book an appointment?</h3>
          <p className="faq-answer">
            For the fastest response, please call our office. You can also reach
            out through our{" "}
            <a href="/contact" className="faq-link">
              Contact Us
            </a>{" "}
            page, and we will be sure to give you a call.
          </p>
        </div>
        <div className="faq-item">
          <h3 className="faq-question">What do I bring to my appointment?</h3>
          <p className="faq-answer">
            Please bring a valid form of identification (e.g., Driver’s License
            or Passport) and your insurance information, if applicable.
          </p>
          <p className="faq-answer">
            If receiving certain treatments, please arrange for someone to drive
            you home.
          </p>
        </div>
        <div className="faq-item">
          <h3 className="faq-question">
            Will I receive my medication in the office?
          </h3>
          <p className="faq-answer">
            Injections are administered in the office. Other medications will be
            available for pickup at your local pharmacy once we call in your
            prescription.
          </p>
        </div>
        <div className="faq-item">
          <h3 className="faq-question">How do I renew my prescription?</h3>
          <p className="faq-answer">
            Please schedule a new appointment. Dr. Rodriguez will assess your
            condition and determine the next steps in your treatment plan.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faqs;