import React, { useState, useEffect } from "react";
import { FaPhone, FaFax, FaEnvelope } from "react-icons/fa";
import "../Styles/Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const API_URL = process.env.REACT_APP_API_URL || "/api/send-email";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowPopup(true);
        setFormData({ firstName: "", lastName: "", email: "", message: "" });
      } else {
        const errorData = await response.json();
        setError(errorData.error || "Something went wrong.");
      }
    } catch (error) {
      setError("Network error. Please check your connection.");
    } finally {
      setLoading(false);
    }
  };
  const [bgLoaded, setBgLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = "/images/contact-us-background.webp";
    img.onload = () => setBgLoaded(true);
  }, []);


  return (
    <div className={`contact-container ${bgLoaded ? "loaded" : ""}`} style={{ 
      backgroundImage: bgLoaded ? "url('/images/contact-us-background.webp')" : "none" 
    }}>
      <div className="contact-content">
        <h2 className="contact-title">Contact Us</h2>
        <div className="contact-sections">
          <div className="contact-info">
            <div className="contact-card">
              <FaPhone className="contact-icon" />
              <p>305-823-1808</p>
            </div>

            <div className="contact-card">
              <FaFax className="contact-icon" />
              <p>305-821-7186</p>
            </div>

            <div className="contact-card">
              <FaEnvelope className="contact-icon" />
              <p>
                <a href="mailto:miramarpainmed3@gmail.com">
                  miramarpainmed3@gmail.com
                </a>
              </p>
            </div>
          </div>

          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button className="submit-button" type="submit" disabled={loading}>
                Submit
              </button>
            </form>
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Thank You!</h3>
            <p>Your message has been sent successfully.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}

      {loading && (
        <div className="popup">
          <div className="popup-content">
            <div className="loading-spinner"></div>
            <p>Please wait while we process your request.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;