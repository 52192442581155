import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Styles/Navbar.css";
import { FiMenu, FiX } from "react-icons/fi";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setMenuOpen(false);
        setIsFadingOut(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("no-scroll");
    }
  }, [menuOpen]);

  const closeMenu = () => {
    document.body.classList.remove("no-scroll");
    setIsFadingOut(true);
    setTimeout(() => {
      setMenuOpen(false);
      setIsFadingOut(false);
    }, 300);
  };

  return (
    <>
      <nav className="navbar">
      <Link to="/" className="logo-link">
        <img src="/images/logo.webp" alt="Logo" className="logo" />
      </Link>
        <ul>
          <li><Link to="/" className={location.pathname === "/" ? "active" : ""}>Home</Link></li>
          <li><Link to="/services" className={location.pathname === "/services" ? "active" : ""}>Services</Link></li>
          <li><Link to="/faqs" className={location.pathname === "/faqs" ? "active" : ""}>FAQs</Link></li>
          <li><Link to="/contact" className={location.pathname === "/contact" ? "active" : ""}>Contact</Link></li>
        </ul>
        <button className="menu-btn" onClick={menuOpen ? closeMenu : () => setMenuOpen(true)}>
          {menuOpen ? <FiX size={30} /> : <FiMenu size={30} />}
        </button>
      </nav>

      {menuOpen && (
        <div className={`overlay ${isFadingOut ? "fade-out" : "fade-in"}`}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/services" onClick={closeMenu}>Services</Link></li>
            <li><Link to="/faqs" onClick={closeMenu}>FAQs</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Navbar;