import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import servicesData from "../data/services";
import "../Styles/Services.css";

const Services = () => {
  const [bgLoaded, setBgLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = "/images/services-banner.webp";
    img.onload = () => setBgLoaded(true);
  }, []);

  return (
    <div className="services-container">
      <div
        className={`banner ${bgLoaded ? "loaded" : ""}`}
        style={{ backgroundImage: `url('/images/services-banner.webp')` }}
      >
        <div className="banner-overlay">Our Services</div>
      </div>

      {Object.entries(servicesData).map(([category, services]) => (
        <div key={category} className="service-category">
          <h2>{category}</h2>
          <ul className="service-list">
            {services.map((service) => {
              return service.details ? (
                <Link
                  key={service.slug}
                  to={`/services/${service.slug}`}
                  className="service-item clickable"
                >
                  <div className="service-item-content">{service.name}</div>
                </Link>
              ) : (
                <li key={service.slug} className="service-item non-clickable">
                  <div className="service-item-content">{service.name}</div>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Services;
